import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
import DefaultButton from '@/builder/sections/section/components/DefaultButton.vue'

export default mixins(Section).extend({
  components: { DefaultButton },
  data () {
    return {
    }
  }
})
